.SizeInput{
  border: 2px solid #00adff;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  .text{
    padding: 5px;
    text-align: center;
    width: 100%;
  }
  .item-step{
    color: #00adff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}