.StudentAnswereds{
  position: relative;
  display: flex;
  align-items: center;
  .icon-item{
    border-radius: 5px !important;
    font-size: 18px !important;
    padding: 0 5px;
    width: auto !important;
  }
  .drop-wrapper{
    position: absolute;
    z-index: 999;
    background: #DFEAF3;
    right: 0;
    top: 58px;
    border: 1px solid #00ADFF;
    overflow: hidden;
    overflow-y: auto;
    max-height: 400px;
    .item{
      // width: 100%;
      // white-space: nowrap;
      // display: flex;
      // flex-direction: row;
      .text{
        // white-space: nowrap;
        padding: 10px;
        border: 1px solid #00ADFF;
        &.isTrue{
          background: #7CB305;
        }
        &.isFalse{
          background: #FF0000;
        }
      }
      .text + .text{
        // margin-left: 5px;
        border-left: 1px solid #00ADFF;
      }
    }
  }
}