.ColorInput{
  position: relative;
  .btn-color{
    width: 44px;
    height: 44px;
    border: 2px solid #00adff;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 10px;
  }
  .draw-content{
    position: absolute;
    z-index: 999;
    right: 50px;
    bottom: 0;
  }
}