.QRCode{
  h2{
    color: black;
    font-size: 26px;
    text-align: center;
  }
  .label{
    font-size: 16px;
    color: gray;
  }
}