body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary{
  background-color:#00adff;
  border-color:#00adff;
  color:#fff;
  &:hover, &:active, &:focus{
    background-color:#fff;
    color:#00adff;
    border-color: #00adff;
  }
  
}

html, body, #root{
  height: 100%;
}

.container, .container-fluid{
  height: calc(100% - 75px);
  display: flex;
  overflow: hidden;
}