@import '../../../../../../../../scss/variables.scss';
@import '../../../../../../../../scss/common.scss';

.rcw-message {
  margin: 10px;
  display: flex;
  word-wrap: break-word;
}

.rcw-client {
  @include message-bubble($turqois-2);
  margin-left: auto;
}

.rcw-response {
  @include message-bubble($grey-2);
}

/* For markdown elements created with default styles */
.rcw-message-text {

  p {
    margin: 0;
  }
}

.rcw-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}
