.Spiner{
  display: flex;
  background: #00adff;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 2;
  align-items: center;
  justify-content: center;
  img{
    width: 400px;
  }
}